export const getPayloadForElement = async (
	blkType,
	pageOrEditedBlock,
	courseId,
	activePage,
	index,
	parentId,
	sourceId
) => {
	let payload = {
		course_id: Number(courseId),
		chapter_id: Number(activePage.chapter_id),
		page_id: Number(activePage.id),
		css_class: pageOrEditedBlock.css_class || null,
		sort_order: pageOrEditedBlock.sort_order || index + 1,
		content: pageOrEditedBlock.content
	};
	if (blkType === 7) {
		payload = {
			...payload,
			pg_list_blk_id: pageOrEditedBlock.pg_list_blk_id || parentId
		};
	} else if (blkType === 8) {
		payload = {
			...payload,
			pg_radio_blk_id: pageOrEditedBlock.pg_radio_blk_id || parentId,
			score: 10,
			correct: Number(pageOrEditedBlock.isTrue)
		};
	} else if (blkType === 1) {
		payload = {
			...payload,
			pg_checkbox_blk_id:
				pageOrEditedBlock.pg_checkbox_blk_id || parentId,
			score: 10,
			correct: Number(pageOrEditedBlock.isTrue)
		};
	} else if (blkType === 2) {
		payload = {
			...payload,
			pg_connect_blk_id: parentId,
			source: pageOrEditedBlock.source,
			pg_connect_blk_node_id: sourceId || ""
		};
	} else if (blkType === 11) {
		const image =
			pageOrEditedBlock.image === null
				? { file_id: pageOrEditedBlock.file_id }
				: { file: pageOrEditedBlock.image };
		payload = {
			...payload,
			content: pageOrEditedBlock.response,
			pg_compare_blk_id: parentId,
			title: "",
			head_level: pageOrEditedBlock.head_level || 2,
			correct: Number(pageOrEditedBlock.isTrue),
			...image
		};
	} else if (blkType === 9) {
		payload = {
			content: pageOrEditedBlock.content,
			pg_text_blk_id: parentId,
			css_class: pageOrEditedBlock.css_class || null,
			sort_order: pageOrEditedBlock.sort_order || index + 1
		};
	}
	return payload;
};
